import React from "react";
import { Row, Col, Container } from "reactstrap";
import { Form, Field, FormSpy } from "react-final-form";
import * as yup from "yup";
import { Button } from "react-bootstrap";
import IntroBanner from "../../../components/IntroBannerRevamp";
import Seo from "../../../components/Seo";
import Layout from "../../../layouts";
import Banner from "../../../images/services/salesforce/Salesforce-Cover2.png";
import ContrastSection from "../../../components/ContrastSection";
import { sendEmail } from "../../../services/contact";
import { generateDownload } from "../../../utils/helperFunctions";

// Partner
import partner from "../../../images/partners/salesforce-partner.png";

// Casestudy
import downloadFile from "../../../documents/Case-Study-salesforce.pdf";
import caseStudyImg from "../../../images/casestudyimg3.jpg";

// Icons
import iconStrategic from "../../../images/services/salesforce/icon/strategic-guidance.svg";
import iconExpertise from "../../../images/services/salesforce/icon/expertise.svg";
import iconInnovation from "../../../images/services/salesforce/icon/innovation.svg";
import iconFlexibility from "../../../images/services/salesforce/icon/flexibility.svg";
import iconRecord from "../../../images/services/salesforce/icon/record.svg";
import iconSatisfaction from "../../../images/services/salesforce/icon/satisfaction.svg";
import iconGlobal from "../../../images/services/salesforce/icon/global.svg";
import iconTrend from "../../../images/services/salesforce/icon/trend.svg";
import iconExpert from "../../../images/services/salesforce/icon/expert.svg";

// SF offering logos
import offer1 from "../../../images/services/salesforce/Offer1.png";
import offer2 from "../../../images/services/salesforce/Offer2.png";
import offer3 from "../../../images/services/salesforce/Offer3.png";
import offer4 from "../../../images/services/salesforce/Offer4.png";
import offer5 from "../../../images/services/salesforce/Offer5.png";
import sfcommerce from "../../../images/services/salesforce/sf-commerce.png";
import sfexp from "../../../images/services/salesforce/sf-experience.png";
import sffsl from "../../../images/services/salesforce/sf-field.png";
import sfhealth from "../../../images/services/salesforce/sf-health.png";
import sfnonprofit from "../../../images/services/salesforce/sf-nonprofit.png";

// Certification logos
import certification1 from "../../../images/services/salesforce/Certification1.png";
import certification2 from "../../../images/services/salesforce/Certification2.png";
import certification3 from "../../../images/services/salesforce/Certification3.png";
import certification4 from "../../../images/services/salesforce/Certification4.png";
import certification5 from "../../../images/services/salesforce/Certification5.png";
import certification6 from "../../../images/services/salesforce/Certification6.png";
import certification7 from "../../../images/services/salesforce/Certification7.png";
import certification8 from "../../../images/services/salesforce/Certification8.png";
import certification9 from "../../../images/services/salesforce/Certification9.png";
import certification10 from "../../../images/services/salesforce/Certification10.png";
import certification11 from "../../../images/services/salesforce/Certification11.png";
import certification12 from "../../../images/services/salesforce/Certification12.png";
import certification13 from "../../../images/services/salesforce/Certification13.png";
import certification14 from "../../../images/services/salesforce/Certification14.png";
import certification15 from "../../../images/services/salesforce/Certification15.png";
import certification16 from "../../../images/services/salesforce/Certification16.png";
import certification17 from "../../../images/services/salesforce/Certification17.png";
import certification18 from "../../../images/services/salesforce/Certification18.png";
import certification19 from "../../../images/services/salesforce/Certification19.png";
import certification20 from "../../../images/services/salesforce/Certification20.png";

// Contact
import UserCard from "../../../components/UserCard";
import SvgIconsSet from "../../../components/SvgIconsSet";
import Chamil from "../../../images/services/salesforce/chamil4.png";

const Salesforce = () => {
  const servicesList = [
    {
      id: "1",
      title: "Salesforce Consulting and Advisory",
      textbold:
        "Need strategic guidance to maximise full potential of Salesforce?",
      textdetail:
        "Kickstart your salesforce journey with strategic consulting services encompassing business process analysis, roadmap development, solution architecture, and change management strategies.",
    },
    {
      id: "2",
      title: "Salesforce Implementation and Customisation",
      textbold:
        "Facing challenges with Salesforce Customisation, Implementation and Migration?",
      textdetail:
        "Leverage our expertise to streamline salesforce implementations, customisations, and migrations, ensuring seamless adoption, user acceptance, and alignment with your unique business requirements.",
    },
    {
      id: "3",
      title: "Salesforce Integration and Data Management",
      textbold: "Looking to Integrate with existing systems?",
      textdetail:
        "Harness the power of data by integrating salesforce with your existing systems, applications, and third-party platforms. Our integration services encompass data migration, API integration, middleware configuration, and master data management to ensure data integrity and interoperability.",
    },
    {
      id: "4",
      title: "Salesforce App Development and Customisation",
      textbold: "Exploring to expand the market via custom app development?",
      textdetail:
        "Extend the functionality of salesforce to meet your specific business needs with custom app development, Lightning component development, and salesforce AppExchange solutions tailored to enhance productivity, collaboration, and customer engagement.",
    },
    {
      id: "5",
      title: "Salesforce Training and Enablement",
      textbold: "Looking to train your staff for productivity?",
      textdetail:
        "Empower your teams with comprehensive salesforce training programs, workshops, and certification courses designed to enhance user proficiency, drive adoption, and maximise ROI from your salesforce investments.",
    },
    {
      id: "6",
      title: "Salesforce Support and Managed Services",
      textbold: "Need long term support for a peace of mind?",
      textdetail:
        "Ensure the ongoing success of your salesforce initiatives with our dedicated support services, including proactive monitoring, troubleshooting, performance optimisation, and continuous improvement initiatives to maximise uptime, reliability, and user satisfaction.",
    },
  ];

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Email must be a valid email")
      .required("Email is a required field"),
  });

  const validate = (values) => {
    const errors = {};
    try {
      schema.validateSync(values, { abortEarly: false });
    } catch (err) {
      err.inner.forEach((error) => {
        errors[error.path] = error.message;
      });
    }
    return errors;
  };

  const onSubmitHandler = async (values) => {
    // eslint-disable-next-line no-undef
    generateDownload(document, [downloadFile]);

    const formData = { ...values };
    formData.Message = "CASE STUDY: OPTIMISING FIELD SERVICE OPERATIONS WITH SALESFORCE FIELD SERVICE LIGHTENING (FSL)";
    formData.FormType = "CaseStudy";
    formData.Subject = "CASE STUDY: SALESFORCE";

    try {
      await sendEmail(formData);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  return (
    <Layout bodyClass="service-page salesforce-service">
      <Seo
        keyword="Salesforce Development Services"
        title="Top Salesforce Development Services | Enhance Your CRM Solutions"
        description="Unlock the full potential of your business with our top-notch Salesforce Development Services. From custom Salesforce solutions to seamless integration, we enhance your CRM strategy to drive growth and efficiency. Discover how we can transform your business today."
      />

      <IntroBanner
        title="Salesforce Development Services"
        subtitle="Salesforce development, integration and app exchange services"
        image={Banner}
      />

      <div className="content">
        <ContrastSection
          title="Elevating Your Salesforce Journey"
          para="Salesforce stands out as a leading intelligent CRM platform for businesses of all sizes driving customer relationship management, business automation, and data-driven insights. Whether you're initiating a new salesforce implementation, optimising existing processes, or seeking to harness the full potential of the platform, partnering with a seasoned salesforce service provider is essential. At iTelaSoft, we offer a holistic suite of salesforce services designed to empower your organisation across every stage of your salesforce journey."
        />
      </div>

      <section className="section-our-partners">
        <Container className="industry-section">
          <Row className="mt-4 industry-partners">
            <Col>
              <div className="container pb-6 section-2">
                <div className="row">
                  <div className="col-md-6 pr-md-5">
                    <img alt="istqb logo" className="img-fluid" src={partner} />
                  </div>
                  <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-end">
                    <p>
                      iTelaSoft, a distinguished Salesforce Consulting Partner,
                      specialises in optimising business outcomes through the
                      strategic implementation of Salesforce CRM solutions.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container className="">
          <div className="title title-1">Why Choose a Salesforce Partner?</div>
          <div className="sub-title">
            Collaborating with a trusted salesforce partner like us presents
            numerous benefits:
          </div>

          <Row className="mt-2">
            <Col>
              <div className="icon-with-text-new">
                <img src={iconStrategic} alt="Icon" />
                <div className="text">
                  <strong>Strategic Guidance</strong>
                  <p>
                    Gain strategic insights and personalised recommendations
                    tailored to your business objectives, industry dynamics, and
                    growth aspirations.
                  </p>
                </div>
              </div>
              <div className="icon-with-text-new">
                <img src={iconExpertise} alt="Icon" />
                <div className="text">
                  <strong>End-to-End Expertise</strong>
                  <p>
                    Access a diverse team of salesforce specialists equipped
                    with deep domain knowledge, technical proficiency, and
                    hands-on experience across various salesforce clouds,
                    including Sales Cloud, Service Cloud, Marketing Cloud,
                    Commerce Cloud, and more.
                  </p>
                </div>
              </div>
              <div className="icon-with-text-new">
                <img src={iconInnovation} alt="Icon" />
                <div className="text">
                  <strong>Innovation and Best Practices</strong>
                  <p>
                    Stay ahead of the curve with access to innovative salesforce
                    technologies, best practices, and industry benchmarks,
                    enabling you to unlock new opportunities, drive innovation,
                    and optimise business outcomes.
                  </p>
                </div>
              </div>
              <div className="icon-with-text-new">
                <img src={iconFlexibility} alt="Icon" />
                <div className="text">
                  <strong>Flexibility and Scalability</strong>
                  <p>
                    Benefit from flexible engagement models, scalable resources,
                    and on-demand services that align with your evolving needs,
                    budget constraints, and project timelines.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container className="service-container mb-4">
          <Col>
            <Row>
              <div>
                <div className="title title-1">
                  Our Comprehensive Salesforce Service Offerings
                </div>
                <div className="sub-title">
                  At iTelaSoft, we offer a comprehensive portfolio of salesforce
                  services to address the diverse needs and challenges faced by
                  organisations across industries:
                </div>
              </div>
              <div className="mt-4">
                {servicesList.map((service) => (
                  <div className="wrapper-service" key={service.id}>
                    <div className="service-details">
                      <div className="service-title">{service.title}</div>
                      <div className="service-content ml-1">
                        <div className="service-breif pb-1">
                          {service.textbold}
                        </div>
                        <div className="service-description">
                          {service.textdetail}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Row>
            <Row className="pt-4">
              <div id="who-depend-on-us">
                <div className="section-our-partners-logo-set">
                  <div className="wrap-logo p-0">
                    <div className="partners-logo">
                      <img src={offer1} alt="" />
                    </div>
                  </div>
                  <div className="wrap-logo p-0">
                    <div className="partners-logo">
                      <img src={sfexp} alt="salesforce-experience" />
                    </div>
                  </div>
                  <div className="wrap-logo p-0">
                    <div className="partners-logo">
                      <img src={offer3} alt="" />
                    </div>
                  </div>
                  <div className="wrap-logo p-0">
                    <div className="partners-logo">
                      <img src={offer4} alt="" />
                    </div>
                  </div>
                  <div className="wrap-logo p-0">
                    <div className="partners-logo">
                      <img src={sfnonprofit} alt="" />
                    </div>
                  </div>
                  <div className="wrap-logo p-0">
                    <div className="partners-logo">
                      <img src={sfcommerce} alt="" />
                    </div>
                  </div>
                  <div className="wrap-logo p-0">
                    <div className="partners-logo">
                      <img src={offer2} alt="" />
                    </div>
                  </div>
                  <div className="wrap-logo p-0">
                    <div className="partners-logo">
                      <img src={sffsl} alt="" />
                    </div>
                  </div>
                  <div className="wrap-logo p-0">
                    <div className="partners-logo">
                      <img src={sfhealth} alt="" />
                    </div>
                  </div>
                  <div className="wrap-logo p-0">
                    <div className="partners-logo">
                      <img src={offer5} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </Col>
        </Container>
      </section>

      <section>
        <div className="wrap-case-study mt-3">
          <Container>
            <div className="row py-6">
              <div className="col-12">
                <div className="career">
                  <div className="">
                    <div className="row justify-content-center">
                      <div className="col-12 col-lg-6 flex-column">
                        <div>
                          <h5 className="title-5 mb-0">Case Study</h5>
                          <h3 className="title-3 mt-0">
                            OPTIMISING FIELD SERVICE OPERATIONS WITH SALESFORCE
                            FIELD SERVICE LIGHTENING (FSL)
                          </h3>
                          <p>
                            Discover how leveraging Salesforce Field Service
                            Lightning revolutionised field operations for this
                            customer, boosting efficiency, transparency, and
                            customer satisfaction. Download the case study now.
                          </p>
                        </div>
                        <div className="mt-2 mb-3">
                          <p><b>Download the Case Study Now!</b></p>
                          <Form
                            onSubmit={onSubmitHandler}
                            validate={validate}
                            render={({ handleSubmit }) => (
                              <form
                                className="case-study-email-form d-flex flex-sm-row flex-column justify-content-md-between mb-1"
                                onSubmit={handleSubmit}
                              >
                                <Field name="email">
                                  {({ input, meta }) => (
                                    <div className="col-lg col-md pr-1 col-sm-7 col-12 p-0">
                                      <input
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...input}
                                        type="email"
                                        placeholder="Enter your e-mail address"
                                      />
                                      {meta.error && meta.touched && (
                                        <span className="error-val">
                                          {meta.error}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                                <FormSpy
                                  subscription={{
                                    hasValidationErrors: true,
                                    submitting: true,
                                  }}
                                >
                                  {(subscriptions) => (
                                    <Button
                                      className={`btn-icon-button mt-1 mt-sm-0 mb-2 ${
                                        subscriptions.hasValidationErrors
                                        || subscriptions.submitting
                                          ? "disabled-button"
                                          : ""
                                      }`}
                                      variant="primary"
                                      type="submit"
                                    >
                                      <span className="btn-text">
                                        Download Case Study
                                      </span>
                                      <span className="btn-icon">
                                        <SvgIconsSet.SideArrow />
                                      </span>
                                    </Button>
                                  )}
                                </FormSpy>
                              </form>
                            )}
                          />

                          <p className="small-text">
                            By downloading this case study, you agree to receive
                            emails from iTelaSoft about products, services, and
                            updates. You can unsubscribe at any time.
                          </p>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <img
                          alt="product innovation"
                          className="img-fluid"
                          src={caseStudyImg}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </section>

      <section>
        <Container>
          <div>
            <div className="title title-1">Why Partner with iTelaSoft?</div>
            <div className="sub-title">
              iTelaSoft is your trusted partner for salesforce success, offering
              unparalleled expertise, innovation, and value across every facet
              of your salesforce journey:
            </div>
          </div>
          <Row className="mt-2">
            <Col>
              <div className="icon-with-text-new">
                <img src={iconRecord} alt="Icon" />
                <div className="text">
                  <strong>Proven Track Record</strong>
                  <p>
                    With a proven track record of successful salesforce
                    implementations, integrations, and optimisations, we bring a
                    wealth of experience and insights to every engagement.
                  </p>
                </div>
              </div>
              <div className="icon-with-text-new">
                <img src={iconSatisfaction} alt="Icon" />
                <div className="text">
                  <strong>Customer-Centric Approach</strong>
                  <p>
                    We prioritise customer satisfaction, collaboration, and
                    transparency, fostering long-term partnerships built on
                    trust, integrity, and shared success.
                  </p>
                </div>
              </div>
              <div className="icon-with-text-new">
                <img src={iconGlobal} alt="Icon" />
                <div className="text">
                  <strong>Global Reach, Local Presence</strong>
                  <p>
                    With a global footprint and local expertise, we serve
                    clients across diverse industries, geographies, and
                    organisational sizes, ensuring personalised service and
                    support tailored to your unique needs.
                  </p>
                </div>
              </div>
              <div className="icon-with-text-new">
                <img src={iconTrend} alt="Icon" />
                <div className="text">
                  <strong>Continuous Innovation</strong>
                  <p>
                    We stay ahead of the latest salesforce innovations, trends,
                    and best practices, enabling us to deliver innovative
                    solutions that drive business transformation, agility, and
                    competitive advantage.
                  </p>
                </div>
              </div>
              <div className="icon-with-text-new">
                <img src={iconExpert} alt="Icon" />
                <div className="text">
                  <strong>Certified Salesforce Experts</strong>
                  <p>
                    Our team comprises certified salesforce consultants,
                    developers, administrators, and architects who possess the
                    skills, certifications, and credentials to deliver
                    exceptional results and exceed your expectations.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <section className="section-our-partners w-100">
              <Container className="industry-section logo-section">
                <Row className="mt-4 industry-partners salesforce-certifications">
                  <Col>
                    <div className="container pb-6 section-2">
                      <div className="row d-flex justify-content-center">
                        <div className="col-md-2 col-sm-6 col-6 cloud-logo">
                          <img
                            alt="salesforce logo"
                            className="img-fluid"
                            src={certification1}
                          />
                        </div>
                        <div className="col-md-2 col-sm-6 col-6 cloud-logo">
                          <img
                            alt="salesforce logo"
                            className="img-fluid"
                            src={certification2}
                          />
                        </div>
                        <div className="col-md-2 col-sm-6 col-6 cloud-logo">
                          <img
                            alt="salesforce logo"
                            className="img-fluid"
                            src={certification3}
                          />
                        </div>
                        <div className="col-md-2 col-sm-6 col-6 cloud-logo">
                          <img
                            alt="salesforce logo"
                            className="img-fluid"
                            src={certification4}
                          />
                        </div>
                        <div className="col-md-2 col-sm-6 col-6 cloud-logo">
                          <img
                            alt="salesforce logo"
                            className="img-fluid"
                            src={certification5}
                          />
                        </div>
                        <div className="col-md-2 col-sm-6 col-6 cloud-logo">
                          <img
                            alt="salesforce logo"
                            className="img-fluid"
                            src={certification6}
                          />
                        </div>
                        <div className="col-md-2 col-sm-6 col-6 cloud-logo">
                          <img
                            alt="salesforce logo"
                            className="img-fluid"
                            src={certification7}
                          />
                        </div>
                        <div className="col-md-2 col-sm-6 col-6 cloud-logo">
                          <img
                            alt="salesforce logo"
                            className="img-fluid"
                            src={certification8}
                          />
                        </div>
                        <div className="col-md-2 col-sm-6 col-6 cloud-logo">
                          <img
                            alt="salesforce logo"
                            className="img-fluid"
                            src={certification9}
                          />
                        </div>
                        <div className="col-md-2 col-sm-6 col-6 cloud-logo">
                          <img
                            alt="salesforce logo"
                            className="img-fluid"
                            src={certification10}
                          />
                        </div>
                        <div className="col-md-2 col-sm-6 col-6 cloud-logo">
                          <img
                            alt="salesforce logo"
                            className="img-fluid"
                            src={certification11}
                          />
                        </div>
                        <div className="col-md-2 col-sm-6 col-6 cloud-logo">
                          <img
                            alt="salesforce logo"
                            className="img-fluid"
                            src={certification12}
                          />
                        </div>
                        <div className="col-md-2 col-sm-6 col-6 cloud-logo">
                          <img
                            alt="salesforce logo"
                            className="img-fluid"
                            src={certification13}
                          />
                        </div>
                        <div className="col-md-2 col-sm-6 col-6 cloud-logo">
                          <img
                            alt="salesforce logo"
                            className="img-fluid"
                            src={certification14}
                          />
                        </div>
                        <div className="col-md-2 col-sm-6 col-6 cloud-logo">
                          <img
                            alt="salesforce logo"
                            className="img-fluid"
                            src={certification15}
                          />
                        </div>
                        <div className="col-md-2 col-sm-6 col-6 cloud-logo">
                          <img
                            alt="salesforce logo"
                            className="img-fluid"
                            src={certification16}
                          />
                        </div>
                        <div className="col-md-2 col-sm-6 col-6 cloud-logo">
                          <img
                            alt="salesforce logo"
                            className="img-fluid"
                            src={certification17}
                          />
                        </div>
                        <div className="col-md-2 col-sm-6 col-6 cloud-logo">
                          <img
                            alt="salesforce logo"
                            className="img-fluid"
                            src={certification18}
                          />
                        </div>
                        <div className="col-md-2 col-sm-6 col-6 cloud-logo">
                          <img
                            alt="salesforce logo"
                            className="img-fluid"
                            src={certification19}
                          />
                        </div>
                        <div className="col-md-2 col-sm-6 col-6 cloud-logo">
                          <img
                            alt="salesforce"
                            className="img-fluid"
                            src={certification20}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </Row>
        </Container>
      </section>

      <section className="section-contact">
        <Container>
          <Row>
            <Col md={9} lg={9} xl={9} className="title-section flex-column">
              <div className="title title-1 desk">Let us know how to help</div>
              <div className="title title-1 mobile">
                Let us know how to help
              </div>
              <div>
                <p>
                  We love to hear what challenges you go through in your
                  business and discuss how we can assist you.
                </p>
              </div>
              <div className="getting-touch-right">
                <a className="btn btn-primary btn-icon-button" href="/contact/">
                  <span className="btn-text">Get in touch</span>
                  <span className="btn-icon">
                    <SvgIconsSet.SideArrow />
                  </span>
                </a>
              </div>
            </Col>
            <Col
              md={3}
              lg={3}
              xl={3}
              className="mb-2 contact-card-section col-6"
            >
              <a
                className="contact-card"
                href="/contact/"
                aria-label="Chamil Madusanka"
              >
                <UserCard
                  name="Chamil Madusanka"
                  detail="Salesforce Adviser/MVP"
                  image={Chamil}
                  link="https://www.linkedin.com/in/chamilmadusanka/"
                />
              </a>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default Salesforce;
